<template>
    <div>
        <v-container
            id="users"
            fluid
            class="fill-height flex-column flex-nowrap align-start"
        >
            <v-container fluid class="pt-5 px-5 mb-10">
                <v-row justify="space-between">
                    <v-col md="4" cols="12" class="d-flex">
                        <v-toolbar-title
                            class="headline font-weight-bold accent--text"
                            >Engage</v-toolbar-title
                        >
                        <v-select
                            v-model="selectedEngagePage"
                            background-color="transparent"
                            item-text="text"
                            item-value="value"
                            :menu-props="{ top: false, offsetY: true }"
                            class="ma-0 pa-0 ml-10 ministry-pages"
                            :items="engageItems"
                            @change="changeView"
                            color="primaryTextColor"
                            :style="`max-width: 200px; font-size: 20px; font-weight: 800;`"
                        ></v-select>
                    </v-col>
                    <v-col md="auto" cols="12" class="d-inline-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Sort By"
                            color="secondary"
                            item-value="value"
                            item-text="text"
                            :items="sorts"
                            style="width: 11.25rem"
                            clearable
                            @click:clear="table.options.sortBySchedule = null"
                            v-model="table.options.sortBySchedule"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Show"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="perPageItems"
                            style="width: 11.25rem; color: black !important"
                            clearable
                            @click:clear="table.options.perPage = 10"
                            v-model="table.pagination.perPage"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col md="6" lg="auto" cols="12">
                        <v-btn
                            class="border-radius-25 px-5 mx-1"
                            depressed
                            rounded
                            dark
                            color="#2C423D"
                            @click="openDailyDevotionFormDialog('add')"
                            >Add Devotion</v-btn
                        >
                    </v-col>
                    <v-col md="6" lg="auto" cols="12" class="d-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by book"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="bibleBooks"
                            item-value="name"
                            item-text="name"
                            small-chips
                            multiple
                            style="max-width: 20rem"
                            v-model="table.options.book"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by date"
                            color="secondary"
                            append-icon="mdi-calendar-outline"
                            style="width: 20rem"
                            readonly
                            :value="dateRangeDescription"
                            @click="isDateRangeDialogOpen = true"
                            clearable
                            @click:clear="clearDateRanges"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-container
                fluid
                class="flex-grow-1 fill-height align-start justify-center"
            >
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            class="custom-table"
                            :headers="table.headers"
                            :loading="table.loading"
                            :items="table.items"
                            :server-items-length="table.pagination.total"
                            :items-per-page.sync="table.pagination.perPage"
                            :page.sync="table.pagination.page"
                            :footer-props="{
                                'items-per-page-options':
                                    table.pagination.itemsPerPageOptions,
                            }"
                            hide-default-footer
                        >
                            <template v-slot:item.image="{ item }">
                                <v-img
                                    class="border-radius-10 mx-auto"
                                    :lazy-src="item.devotion_image"
                                    :src="item.devotion_image"
                                    :height="item.devotion_image ? '80' : '10'"
                                    width="130"
                                ></v-img>
                            </template>
                            <template v-slot:item.bible_book="{ item }">
                                <span class="font-weight-bold success--text">{{
                                    item.bible_book.name
                                }}</span>
                            </template>
                            <template v-slot:item.links="{ item }">
                                <v-tooltip bottom v-if="item.youtube_link">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            :href="item.youtube_link"
                                            target="_blank"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-youtube</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Open YouTube</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="item.bible_url">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            :href="item.bible_url"
                                            target="_blank"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-book-cross</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Open Bible App</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:item.schedule="{ item }">
                                {{ formatDate(item.schedule_at) }}
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-hover v-slot="{ hover }">
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#389877' : '#78756A20'"
                                        rounded
                                        @click="
                                            openEventInformationDialog(item)
                                        "
                                    >
                                        <v-icon
                                            :color="hover ? '#fff' : '#78756A'"
                                            >mdi-fullscreen</v-icon
                                        >
                                    </v-btn>
                                </v-hover>
                            </template>
                        </v-data-table>
                        <Pagination
                            :total-pages="table.pagination.lastPage"
                            :current-page="table.pagination.currentPage"
                            @change="onPaginationChange"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <engage-daily-devotion-form-dialog
            :is-open.sync="dailyDevotionForm.show"
            :action="dailyDevotionForm.action"
            :data="dailyDevotionForm.data"
            @onSuccess="onFinishUpdateDeleteEvent"
            @close="
                dailyDevotionForm = {
                    ...dailyDevotionForm,
                    show: false,
                    data: null,
                }
            "
        ></engage-daily-devotion-form-dialog>

        <engage-daily-devotion-information-dialog
            :is-open.sync="dailyDevotionInformationDialog.show"
            :data="dailyDevotionInformationDialog.data"
            @close="dailyDevotionInformationDialog.show = false"
            @showUpdateForm="openDailyDevotionFormDialog('edit')"
            @onDeleteSuccess="onFinishUpdateDeleteEvent"
            v-if="dailyDevotionInformationDialog.show"
        ></engage-daily-devotion-information-dialog>

        <base-date-range-dialog
            :is-open.sync="isDateRangeDialogOpen"
            :start-date.sync="table.options.from"
            :end-date.sync="table.options.to"
            :selected-date-range.sync="dateRangeDescription"
        ></base-date-range-dialog>
    </div>
</template>

<script>
import Pagination from "../../../Core/Pagination.vue";
import BaseDateRangeDialog from "@/components/Base/BaseDateRangeDialog";
import dateUtility from "@/mixins/date";
import helperUtility from "@/mixins/helper";
import { GET_DAILY_DEVOTIONS } from "@/store/modules/dailyDevotion";
import EngageDailyDevotionFormDialog from "@/components/MainView/Engage/DailyDevotions/components/DailyDevotionFormDialog";
import EngageDailyDevotionInformationDialog from "@/components/MainView/Engage/DailyDevotions/components/DailyDevotionInformationDialog";
import { GET_BIBLE_BOOKS } from "@/store/modules/selection";

export default {
    name: "engage-events",

    components: {
        EngageDailyDevotionInformationDialog,
        EngageDailyDevotionFormDialog,
        Pagination,
        BaseDateRangeDialog,
    },

    data() {
        return {
            table: {
                items: [],
                options: {
                    sortBySchedule: "desc",
                    from: null,
                    to: null,
                    book: null,
                },
                headers: [
                    { text: "Devotion Image", value: "image", sortable: false },
                    {
                        text: "Bible Book",
                        value: "bible_book",
                        sortable: false,
                    },
                    {
                        text: "Title",
                        value: "title",
                        sortable: false,
                    },
                    {
                        text: "Verse",
                        value: "bible_verse",
                        sortable: false,
                    },
                    {
                        text: "Schedule",
                        value: "schedule",
                        sortable: false,
                    },
                    {
                        text: "Links",
                        value: "links",
                        sortable: false,
                    },
                    {
                        text: "",
                        value: "action",
                        sortable: false,
                        width: "130px",
                    },
                ],
                search: null,
                loading: false,
                pagination: {
                    page: 1,
                    perPage: 10,
                    total: 0,
                    itemsPerPageOptions: [5, 10, 15, 20],
                    lastPage: 1,
                    currentPage: 1,
                },
            },
            calendar: {
                items: [],
                options: {
                    from: null,
                    to: null,
                },
                pagination: {
                    page: 1,
                },
                loading: false,
            },
            isDateRangeDialogOpen: false,
            dateRangeDescription: null,
            dailyDevotionForm: { show: false, action: "", data: null },
            dailyDevotionInformationDialog: { show: false, data: null },
            selectedEngagePage: "/engage/daily-devotions",
            engageItems: [
                {
                    text: "Sessions",
                    value: "/engage/sessions",
                },
                {
                    text: "Prayer Requests",
                    value: "/engage/prayer-requests",
                },
                {
                    text: "#TakeAMomentToPray",
                    value: "/engage/daily-devotions",
                },
                {
                    text: "Events Calendar",
                    value: "/engage/events-calendar",
                },
            ],
            show: false,
            focus: "",
        };
    },

    watch: {
        ["table.options"]: {
            handler() {
                this.table.pagination.page = 1;
                this.getDailyDevotions();
            },
            deep: true,
        },

        ["table.pagination.perPage"]: {
            handler() {
                this.table.pagination.page = 1;
                this.getDailyDevotions();
            },
        },

        async mode(value) {
            if (value === "calendar") {
                this.calendar.options = Object.assign(
                    {},
                    {
                        from: dateUtility.toUTC(
                            dateUtility.getSpecificDayOfMonth("start", null)
                        ),
                        to: dateUtility.toUTC(
                            dateUtility.getSpecificDayOfMonth("end", null)
                        ),
                    }
                );
                await this.getEventsCalendar();
            } else {
                await this.getDailyDevotions();
            }
        },
    },

    computed: {
        sorts() {
            return this.$store.state.selection.sorts;
        },

        perPageItems() {
            return this.$store.state.selection.perPageItems;
        },

        bibleBooks() {
            return this.$store.state.selection.bibleBooks;
        },
    },

    methods: {
        async getDailyDevotions() {
            const payload = {
                ...this.table.pagination,
                ...this.table.options,
            };
            this.table.loading = true;
            const { success, data } = await this.$store.dispatch(
                GET_DAILY_DEVOTIONS,
                payload
            );
            if (success) {
                this.table.pagination.currentPage = data.meta.current_page || 0;
                this.table.pagination.total = data.meta.total || 0;
                this.table.pagination.lastPage = data.meta.last_page || 0;
                this.table.items = [...data.data];
                this.table.loading = false;
                return;
            }
            this.table.loading = false;
        },

        async onPaginationChange(page) {
            this.table.pagination.page = page;
            await this.getDailyDevotions();
        },

        openDailyDevotionFormDialog(action) {
            switch (action) {
                case "add":
                    this.dailyDevotionForm = { show: true, action, data: null };
                    break;
                case "edit":
                    this.dailyDevotionForm = {
                        show: true,
                        action,
                        data: Object.assign(
                            {},
                            this.dailyDevotionInformationDialog.data
                        ),
                    };
                    this.dailyDevotionInformationDialog.show = false;
                    break;
                case "delete":
                    break;
                default:
                    this.dailyDevotionForm = { show: true, action, data: null };
                    break;
            }
        },

        clearDateRanges() {
            this.table.options.from = null;
            this.table.options.to = null;
        },

        onOpenUpdateDailyDevotionForm({ data }) {
            this.dailyDevotionInformationDialog = Object.assign(
                {},
                {
                    show: false,
                    data: Object.assign({}, data),
                }
            );
            this.openDailyDevotionFormDialog("edit");
        },

        formatDate(scheduleAt) {
            return dateUtility.formatTimestampToSchedule(
                dateUtility.toLocalDate(scheduleAt)
            );
        },

        openEventInformationDialog(data) {
            this.dailyDevotionInformationDialog = Object.assign(
                {},
                {
                    data: Object.assign({}, data),
                    show: true,
                }
            );
        },

        changeView(to) {
            this.$router.push(to);
        },

        copyToClipboard(text) {
            helperUtility.copyText(text);
            this.$notify({
                group: "main-notifications",
                type: "success",
                title: "Copied to Clipboard",
                text: "Invite Link was copied to clipboard.",
            });
        },

        setToday() {
            this.focus = "";
        },

        async prev() {
            this.$refs.calendar.prev();
            this.$nextTick(async () => {
                this.calendar.options = Object.assign(
                    {},
                    {
                        from: dateUtility.toUTC(
                            dateUtility.getSpecificDayOfMonth(
                                "start",
                                this.getMonth
                            )
                        ),
                        to: dateUtility.toUTC(
                            dateUtility.getSpecificDayOfMonth(
                                "end",
                                this.getMonth
                            )
                        ),
                    }
                );
                await this.getEventsCalendar();
            });
        },

        next() {
            this.$refs.calendar.next();
            this.$nextTick(async () => {
                this.calendar.options = Object.assign(
                    {},
                    {
                        from: dateUtility.toUTC(
                            dateUtility.getSpecificDayOfMonth(
                                "start",
                                this.getMonth
                            )
                        ),
                        to: dateUtility.toUTC(
                            dateUtility.getSpecificDayOfMonth(
                                "end",
                                this.getMonth
                            )
                        ),
                    }
                );
                await this.getEventsCalendar();
            });
        },

        onClickCalendarEvent({ event }) {
            const { data } = event;
            this.openEventInformationDialog(data);
        },

        async onFinishUpdateDeleteEvent() {
            await this.getDailyDevotions();
        },

        async getBibleBooks() {
            await this.$store.dispatch(GET_BIBLE_BOOKS);
        },

        async initiate() {
            this.selectedEngagePage = this.$route.path;
            await this.getDailyDevotions();
            await this.getBibleBooks();
        },
    },

    created() {
        this.initiate();
    },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-input__control {
    max-height: 20px !important;
}
.ministry-pages::v-deep .v-select__selection--comma {
    color: #78756a;
}
</style>
